import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DataServiceService } from '../helpers/data.service';
import { ConfigService } from '../helpers/config.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    standalone: false
})
export class HomeComponent {
  csData:any = {};
  selected:any = {}
  openTabIndex:any = undefined;
  responsiveOptions = [
    {
      breakpoint: '800px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  caruselList:any = undefined;
  caruselListNoPopular:any = undefined;
  showCsData:boolean = true;
  constructor(private router: Router, public data:DataServiceService, public cs: ConfigService){
    this.data.setIsCouponLastStep(false);
    this.data.setHeaderMenuSelection('1');
  }

  async ngOnInit(): Promise<void> {
    this.csData = {};
    this.showCsData = true;
    await this.cs.getConfigurations('home', 'en');
    this.data.setCsObj(this.cs);
    this.csData = this.cs;
    setTimeout(() => {
        this.showCsData = false;
    }, 100);
  }

  goToCoupon() {
    let obj = this.data.selectedCoupon;
    if (obj && obj.type) {
      if (obj.link) {
        this.router.navigate(['/' + obj.link]);
      } else {
        this.router.navigate(['/' + obj.type]);
      }
    }
  }

  getCaruselList() {
    let locationsByID = [1,2,23,22,24,14,12,5,21,8,12,18];
    let arr: any[] = [];
    this.caruselList = [];
    if (this.data && this.data.couponArray) {
      arr = this.data.couponArray.filter((obj: any) => obj.isPrimary);
      if (arr) {
        for (var index in locationsByID) {
          let obj = {};
          let id = locationsByID[index].toString() || '';
          obj = arr.find((obj: any) => obj.id === id);
          if (obj) {
            this.caruselList.push(obj);
          }
        }
      }
    }
    return this.caruselList;
  }

  getCaruselListNoPopular() {
    let locationsByID = [1,23,22,24,14,12,5,21,8,18];
    let arr: any[] = [];
    this.caruselListNoPopular = [];
    if (this.data && this.data.couponArray) {
      arr = this.data.couponArray.filter((obj: any) => obj.isPrimary);
      if (arr) {
        for (var index in locationsByID) {
          let obj = {};
          let id = locationsByID[index].toString() || '';
          obj = arr.find((obj: any) => obj.id === id);
          if (obj) {
            this.caruselListNoPopular.push(obj);
          }
        }
      }
    }
    return this.caruselListNoPopular;
  }

  changeSelectedTabIndex(e:any) {
    if (e) {
      this.openTabIndex =e.index;
    }
  }

}
