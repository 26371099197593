import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../helpers/config.service';
import { DataServiceService } from '../../helpers/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import {WindowRefService} from "../../helpers/windowref.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-xandr-coupon-step3',
    templateUrl: './xandr-coupon-step3.component.html',
    styleUrl: './xandr-coupon-step3.component.scss',
    standalone: false
})
export class XandrCouponStep3Component implements OnInit, OnDestroy {
  csData:any = {};
  showCsData:boolean = true;
  showVideo:boolean = false;
  showConfetti:boolean = false;
  componentDestroyed: Subject<boolean> = new Subject();
  action: string = 'link';
  constructor(public data:DataServiceService, public cs: ConfigService, private router: Router,
              private activatedRoute: ActivatedRoute, private winRef: WindowRefService, ){
    this.data.setIsCouponLastStep(false);
    this.data.setHeaderMenuSelection('9');
    this.getCsData();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.componentDestroyed)).subscribe(params => {
      if (params['action']) {
        this.action =params['action'];
      }
    });
    document.body.scrollTop = 0;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  linkAccount(): void {
    this.winRef.reportGtagEventNew('pageViewStep4');
    this.router.navigate(['/xndr']);
  }

  async getCsData(): Promise<void> {
    this.csData = {};
    this.showCsData = true;
    await this.cs.getConfigurations('xandr', 'en');
    this.data.setCsObj(this.cs);
    this.csData = this.cs;
    setTimeout(() => {
        this.showCsData = false;
    }, 100);
  }

}
