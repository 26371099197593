<!--<div class="coupon-layout google-coupon">
    <div class="adcore-logo last-step"><a href="https://www.adcore.com" target="_blank"><i></i></a></div>
    <div class="show-coupon">
        <div class="confetti-cover" *ngIf="showConfetti"></div>
        <div class="image"></div>
        <div class="content">
          <div *ngIf="action === 'link'">
            <span>您的优惠券申请已提交！</span>
          </div>
          <div *ngIf="action === 'create'">
            <span>账号创建成功！</span>
          </div>
        </div>
        <div class="video-mobile-view">
            <app-ad-video [showVideo]="true" title="我在哪里查看我的优惠券？" [isMobile]="true" videoName="google-video"></app-ad-video>
        </div>
        <div class="video-desk-view">
            <app-ad-video [showVideo]="showVideo" title="我在哪里查看我的优惠券？" [isMobile]="false" videoName="google-video"></app-ad-video>
        </div>
        <div class="google-ad-text">
          <div *ngIf="action === 'link'">
            如果您的账户符合条件，谷歌可能需要最多35天来应用优惠券。<br class="space"> 有关账户符合条件和促销代码政策的更多信息，请参阅谷歌的<a href="https://support.google.com/adspolicy/answer/1396257?hl=en" target="_blank">条款和条件</a>.
          </div>
          <div *ngIf="action === 'create'">
            感谢您通过Couponer创建账号。 如果您的账号符合条件，您将收到电子邮件通知。谷歌可能需要最多35天来应用优惠券。<br class="space"> 有关账户符合条件和促销代码政策的更多信息，请参阅谷歌的<a href="https://support.google.com/adspolicy/answer/1396257?hl=en" target="_blank">条款和条件</a>.
          </div>
        </div>
        <div class="buttons">
            <div class="get-coupon-btn"><a href="https://couponer.app/ms-cn" target="_blank"><span class="icon-box"><i></i></span><span class="text">申请 $400 的 Microsoft Ads 优惠券</span></a></div>
            <span class="or desktop">或者</span>
            <div class="another-acc-btn"><p-button styleClass="link-btn" label="链接另一个谷歌账户" (onClick)="linkAccount()"></p-button></div>
            <div class="or mobile">或者</div>
        </div>
        <div class="apps-main-title">试用我们的其他广告科技工具</div>
        <div class="apps">
            <app-items-app [lang]="'cn'" src="google"></app-items-app>
        </div>
    </div>
    <app-questions [listItemsType]="'cnGoogle'" [title]="'常见问题'" [isLastStep]="true"></app-questions>
  <app-footer [lang]="'cn'"></app-footer>
</div> -->

<div class="coupon-layout fixed-header">
  <app-header></app-header>
  <div class="main-content">
      <div>
          <div>
            <div class="coupon-layout google-coupon">
              <div class="show-coupon">
                <div><i class="gl-palette main-logo"></i></div>
                <div class="content">
                  <div>
                    <div *ngIf="action === 'link'">
                      <span>您的优惠券申请已提交！</span>
                    </div>
                    <div *ngIf="action === 'create'">
                      <span>账号创建成功！</span>
                    </div>
                  </div>
                </div>
                <div class="google-ad-text">
                  <div *ngIf="action === 'link'">
                    If your account is eligible, it may take Google up to 35 days to apply the coupon.<br class="space"> For details on eligibility and promotional codes, see <a href="https://support.google.com/adspolicy/answer/1396257?hl=en" target="_blank"><span>Google’s T&Cs.</span></a>
                  </div>
                  <div *ngIf="action === 'create'">
                    感谢您通过Couponer创建账号。 如果您的账号符合条件，您将收到电子邮件通知。谷歌可能需要最多35天来应用优惠券。<br class="space"> 有关账户符合条件和促销代码政策的更多信息，请参阅谷歌的<a href="https://support.google.com/adspolicy/answer/1396257?hl=en" target="_blank">条款和条件</a>.
                  </div>
                </div>
                <div class="popular-coupon">
                  <h3>Get Microsoft Advertising coupon</h3>
                  <div class="coupon-box-large-layout">
                    <app-coupon-box [listItem]="this.data.popularCouponItem" [isSecondaryLayout]="true" (redeemEvent)="this.data.goToCoupon('3')"></app-coupon-box>
                  </div>
                </div>
                <div class="buttons">
                  <!--<div class="get-coupon-btn"><a href="https://couponer.app/ms" target="_blank"><span class="icon-box"><i></i></span><span class="text">Get $400 Microsoft Ads coupon</span></a></div>-->
                  <span class="or">Or</span>
                  <div class="another-acc-btn"><p-button styleClass="link-btn" label="Link another Google account" (onClick)="linkAccount()"></p-button></div>
                  <!--<div class="or mobile">Or</div>-->
                </div>
              </div>
            </div>

            <h3 class="explore-title">Explore all coupons</h3>
            <div class="coupon-layout-home-body">
                <app-all-coupons></app-all-coupons>
            </div>
          </div>
      </div>
      <app-footer [lang]="'en'"></app-footer>
  </div>
</div>
