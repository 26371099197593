import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Location } from "@angular/common";
import { DataServiceService } from '../../helpers/data.service';
import {PhoneComponent, phoneNumberValidator} from "../phone/phone.component";
import {WindowRefService} from "../../helpers/windowref.service"
import {lastValueFrom} from "rxjs";
import {ApiCallService} from "../../helpers/api-call.service";

@Component({
    selector: 'app-coupon-selection-dialog',
    templateUrl: './coupon-selection-dialog.component.html',
    styleUrl: './coupon-selection-dialog.component.scss',
    standalone: false
})
export class CouponSelectionDialogComponent {
  url: string = '';
  emailError: string | null = null;
  phoneError: string | null = null;
  websiteError: string | null = null;
  @ViewChild('phone') phone: PhoneComponent | undefined;
  redeemInfo: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    websiteUrl: '',
    createAccount: false,
    couponValue: ''
  };
  currentCountryCode = 'US';

  constructor(public config: DynamicDialogConfig, public router: Router, location: Location,
              private apiCallService: ApiCallService, public data:DataServiceService,
              private dialogRef: DynamicDialogRef,  private winRef: WindowRefService) {
    this.url = location.path();
  }
  ngOnInit() {
    const storedRedeemInfo = sessionStorage.getItem('redeemInfo');
    if (storedRedeemInfo) {
      // Parse the JSON string back to an object
      this.redeemInfo = JSON.parse(storedRedeemInfo);
    }
    this.redeemInfo.couponValue = this.data.selectedCoupon.price;
    const nativeGeoIp = this.winRef.nativeGeoIp;
    if (nativeGeoIp) {
      nativeGeoIp.country((geoipResponse: any) => {
          this.currentCountryCode = geoipResponse.country.iso_code;
        },
        (err: any) => {
          console.log('Error', err);
        });
    }
  }

  validate(): boolean {
    this.emailError = null;
    this.websiteError = null;
    this.phoneError = null;
    const re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$');
    if (!re.test(this.redeemInfo.email || '')) {
      this.emailError = 'Please enter a valid email.';
    }
    if (Boolean(phoneNumberValidator(this.phone))) {
      if (this.phone?.fullPhoneNumber) {
        this.phoneError = 'Please enter a valid phone number.';
        this.phone.fullPhoneNumber.internationalNumber = null;
      }
    }
    if (this.redeemInfo.websiteUrl && this.redeemInfo.websiteUrl.length) {
      const re2 = new RegExp('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$');
      if (!re2.test(this.redeemInfo.websiteUrl || '')) {
        this.websiteError = 'Please enter a valid website url.';
      }
    }
    return Boolean(!this.emailError && !this.websiteError && !this.phoneError);
  }

  onPhoneOnChange(event: any): void {
    this.redeemInfo.phoneNumber = this.phone?.fullPhoneNumber?.internationalNumber || this.phone?.fullPhoneNumber?.number || '';
    if (Boolean(!phoneNumberValidator(this.phone))) {
      this.phoneError = null;
    }
  }

  removeError(event: string): void {
    if (event === 'website') {
      this.websiteError = null;
    }
    if (event === 'email') {
      this.emailError = null;
    }
  }

  checkDisabled(): boolean {
    const disabledArray = [this.redeemInfo.firstName.length, this.redeemInfo.lastName.length,
        this.redeemInfo.email.length, this.redeemInfo.phoneNumber.length, this.redeemInfo.websiteUrl.length]
    return disabledArray.includes(0);
    }

  closeDialog() {
    this.dialogRef.close();
    this.dialogRef.destroy();
  }

  async redeem() {
    if (this.validate()) {
      const recaptchaToken = await this.apiCallService.executeRecaptcha('redeem_' + this.data.couponFullName);
      const params = {
        redeem_info: this.redeemInfo,
        sessionToken: sessionStorage.getItem('token'),
        channel: this.data.couponFullName,
        recaptchaToken
      };
      this.apiCallService.submitRedeemInfo(params);
      sessionStorage.setItem('redeemInfo', JSON.stringify(this.redeemInfo));
      setTimeout(() => {
        this.data.setIsCouponLastStep(true);
        this.closeDialog();
        this.router.navigate([this.url + '/thank_you']);
      }, 2000);
    }
  }

}
