import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataServiceService } from '../../helpers/data.service';

@Component({
    selector: 'app-coupon-box',
    templateUrl: './coupon-box.component.html',
    styleUrl: './coupon-box.component.scss',
    standalone: false
})
export class CouponBoxComponent {

  @Input() isSecondaryLayout: boolean = false;
  @Input() listItem:any = {};
  @Input() isSelectionMode:boolean = false;
  @Output() redeemEvent = new EventEmitter<string>();

   constructor(public data: DataServiceService) {
  }

  ngOnInit() {

  }

  getStyleClass() {
    let style:string = 'coupon-box';
    if (this.isSecondaryLayout) {
      style= style + ' large-layout';
    }
    if (this.listItem && this.listItem.type) {
      style= style + ' ' + this.listItem.type + '-palette';
    }
    return style;
  }

  selectCoupon():void {
    this.data.changeSelectedCoupon(this.listItem);
  }

  redeemFunction() {
    this.data.changeSelectedCoupon(this.listItem);
    this.redeemEvent.emit();
  }

}
