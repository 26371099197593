<div class="dialog-box">
  <div class="create-logo" [ngClass]="page === 'google' ? 'google-logo' : 'youtube-logo'">
  </div>
  <div class="middle-text">
    <span>
      Create a new <b>{{page==='youtube' ? 'Youtube ': ''}}Google Ads</b> account through our Media Blast app and unlock a $500 coupon for eligible accounts!
    </span>
  </div>
  <div class="mb-btn">
    <p-button  label="Continue to Media Blast" styleClass="primary-btn select-coupon" (onClick)="goToMediaBlast()"></p-button>
  </div>
</div>
