import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {lastValueFrom, Subject} from 'rxjs';
import {ApiCallService} from "../../helpers/api-call.service";
import {WindowRefService} from "../../helpers/windowref.service";
import {MessageService} from "primeng/api";
import {NavigationService} from "../../helpers/navigation.service";
import { DataServiceService } from 'src/app/helpers/data.service';

@Component({
    selector: 'app-microsoft-coupon-step2',
    templateUrl: './microsoft-coupon-step2.component.html',
    styleUrls: ['./microsoft-coupon-step2.component.scss'],
    standalone: false
})
export class MicrosoftCouponStep2Component implements OnInit, OnDestroy {
  componentDestroyed: Subject<boolean> = new Subject();
  loading: boolean = false;
  geo: string = 'Global';
  openTabIndex:any = undefined;
  metricData:any = {
    link: {
      amount: '2,850',
      text: 'Accounts Linked',
    },
    redeemed: {
      amount: '1,583',
      text: 'Coupons Redeemed',
    },
    saved: {
      amount: '$633,200',
      text: 'Saved',
    }
  };
  page = 'microsoft';
  constructor(private router: Router, private apiCallService: ApiCallService, private winRef: WindowRefService,
              private messageService: MessageService, private navigationService: NavigationService, public data:DataServiceService) {
                this.data.setHeaderMenuSelection('3');
               }

  ngOnInit(): void {
    this.navigationService.canNavigateToStep3Microsoft = false;
    this.winRef.reportGtagEventNew('pageViewStep2');
    document.body.scrollTop = 0;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  goToEligibleLink(channel:string) {
    this.data.goToEligibleLink(channel);
   }
   
  changeSelectedTabIndex(e:any) {
    if (e) {
      this.openTabIndex =e.index;
    }
  }

  async RedeemCoupon() {
    const info = sessionStorage.getItem('microsoftInfo');
    const sessionToken = sessionStorage.getItem('microsoftToken');
    const passedList = sessionStorage.getItem("microsoftPassedList")
    if (info && sessionToken && passedList) {
      const infoJson = JSON.parse(info);
      const refreshToken = infoJson.refresh_token;
      const customerIdList = JSON.parse(passedList);
      if (refreshToken && customerIdList && customerIdList.length) {
        this.loading = true;
        const params = {
          refresh_token: refreshToken,
          customer_id_list: customerIdList,
          session_token: sessionToken,
          geo: this.geo,
          utm_source: infoJson.utm_source,
          utm_medium: infoJson.utm_medium,
          page: this.page
        };
        const accept$ = this.apiCallService.accept(params, 'microsoft');
        const response = await lastValueFrom(accept$);
        this.loading = false;
        if (response.result == 'success'){
          sessionStorage.removeItem("microsoftInfo");
          sessionStorage.removeItem("microsoftToken");
          sessionStorage.removeItem("microsoftPassedList")
          this.navigationService.canNavigateToStep3Microsoft = true;
          this.router.navigate(['/ms/step3']);
        } else {
          this.messageService.add({
            key: 'msgTemplate',
            severity: 'error',
            summary: 'We apologize for the inconvenience.',
            detail: "Microsoft is having trouble connecting your account. Please try again later or contact couponer@adcore.com",
            closable: true,
            sticky: true
          })
        }
      }
    }
  }

  got_it(): void {
    this.navigationService.canNavigateToStep3Microsoft = true;
    this.router.navigate(['/ms/step3']);
  }
}
