import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {interval, Observable, takeUntil, timer} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {filter, switchMap, take} from "rxjs/operators";

export const ADMIN_COOKIE_TOKEN = 'couponer-admin-token';
export const ADMIN_COOKIE_USER_NAME = 'couponer-admin-user-name';
export const GOOGLE_LINK_TASK_ID = 'couponer-google-link-task-id';
export const YT_LINK_TASK_ID = 'couponer-yt-link-task-id';
export const MS_LINK_TASK_ID = 'couponer-ms-link-task-id';
export const NETFLIX_LINK_TASK_ID = 'couponer-netflix-link-task-id';
export const IN_LINK_TASK_ID = 'couponer-linkedin-task-id';
export const YAHOO_LINK_TASK_ID = 'couponer-yahoo-task-id';
export const XANDR_LINK_TASK_ID = 'couponer-XANDR-task-id';
export const LINK_TASK_ID_EXPIRE_HOUR = 3;
declare var grecaptcha: any;
@Injectable({
  providedIn: 'root'
})

export class ApiCallService {
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  get apiUrl(): string {
    return environment.apiUrl;
  }

  private get httpOptions(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  }
  private get adminHttpOptions(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-admin-session-token': this.adminToken,
        'x-admin-user-name': this.adminUserName,
        'x-couponer-credential-id': 'w613hjahsd623hkjhsd277asdsad4325',
      })
    };
  }

  login(params?: any): Observable<any> {
    if (!params) {
      params = {};
    }
    const apiUrl = `${environment.apiUrl}/v1/admin/login`;
    return this.http.post<any>(apiUrl, params, this.adminHttpOptions)
  }

  getConfigurations(api: string = 'admin', coupon?: string): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/${api}/configurations/get/${coupon || ''}`;
    return this.http.get<any>(apiUrl, (api === 'admin') ? this.adminHttpOptions : this.httpOptions)
  }
  updateConfiguration(cid: string, data: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/admin/configurations/update`;
    return this.http.post<any>(apiUrl, {cid, data}, this.adminHttpOptions)
  }
  get adminToken(): string {
    return this.cookieService.get(ADMIN_COOKIE_TOKEN);
  }

  set adminToken(value: string) {
    const d = new Date();
    d.setDate(d.getDate() + 365);
    this.cookieService.set(ADMIN_COOKIE_TOKEN, value, d, '/');
  }

  get adminUserName(): string {
    return this.cookieService.get(ADMIN_COOKIE_USER_NAME);
  }

  set adminUserName(value: string) {
    const d = new Date();
    d.setDate(d.getDate() + 365);
    this.cookieService.set(ADMIN_COOKIE_USER_NAME, value, d, '/');
  }

  private setTaskId(key: string, value: string): void {
    const d = new Date();
    d.setTime(d.getTime() + (LINK_TASK_ID_EXPIRE_HOUR * 60 * 60 * 1000));
    this.cookieService.set(key, value, d, '/');
  }

// Create a generic method to handle cookie getting
  private getTaskId(key: string): string {
    return this.cookieService.get(key);
  }

// Create a generic method to handle cookie deleting
  private deleteTaskId(key: string): void {
    this.cookieService.delete(key, '/');
  }

// Google Link Task ID
  get googleLinkTaskId(): string {
    return this.getTaskId(GOOGLE_LINK_TASK_ID);
  }

  set googleLinkTaskId(value: string) {
    this.setTaskId(GOOGLE_LINK_TASK_ID, value);
  }

  public deleteGoogleLinkTaskId(): void {
    this.deleteTaskId(GOOGLE_LINK_TASK_ID);
  }

// YouTube Link Task ID
  get ytLinkTaskId(): string {
    return this.getTaskId(YT_LINK_TASK_ID);
  }

  set ytLinkTaskId(value: string) {
    this.setTaskId(YT_LINK_TASK_ID, value);
  }

  public deleteYtLinkTaskId(): void {
    this.deleteTaskId(YT_LINK_TASK_ID);
  }

// Microsoft Link Task ID
  get msLinkTaskId(): string {
    return this.getTaskId(MS_LINK_TASK_ID);
  }

  set msLinkTaskId(value: string) {
    this.setTaskId(MS_LINK_TASK_ID, value);
  }

  public deleteMsLinkTaskId(): void {
    this.deleteTaskId(MS_LINK_TASK_ID);
  }

  // Netflix Link Task ID
  get netflixLinkTaskId(): string {
    return this.getTaskId(NETFLIX_LINK_TASK_ID);
  }

  set netflixLinkTaskId(value: string) {
    this.setTaskId(NETFLIX_LINK_TASK_ID, value);
  }

  public deleteNetflixLinkTaskId(): void {
    this.deleteTaskId(NETFLIX_LINK_TASK_ID);
  }

// LinkedIn Link Task ID
  get inLinkTaskId(): string {
    return this.getTaskId(IN_LINK_TASK_ID);
  }

  set inLinkTaskId(value: string) {
    this.setTaskId(IN_LINK_TASK_ID, value);
  }

  public deleteInLinkTaskId(): void {
    this.deleteTaskId(IN_LINK_TASK_ID);
  }

// Yahoo Link Task ID
  get yahooLinkTaskId(): string {
    return this.getTaskId(YAHOO_LINK_TASK_ID);
  }

  set yahooLinkTaskId(value: string) {
    this.setTaskId(YAHOO_LINK_TASK_ID, value);
  }

  public deleteYahooLinkTaskId(): void {
    this.deleteTaskId(YAHOO_LINK_TASK_ID);
  }

// Xandr Link Task ID
  get xandrLinkTaskId(): string {
    return this.getTaskId(XANDR_LINK_TASK_ID);
  }

  set xandrLinkTaskId(value: string) {
    this.setTaskId(XANDR_LINK_TASK_ID, value);
  }

  public deleteXandrLinkTaskId(): void {
    this.deleteTaskId(XANDR_LINK_TASK_ID);
  }

  public timeoutTask(taskId: string, sessionToken: string | null) {
    const params = {
      task_id: taskId,
      session_token: sessionToken,
      status: 'timeout'
    }
    const apiUrl = `${this.apiUrl}/v1/set_task_status`;
    return this.http.post<any>(apiUrl, params, this.httpOptions);
  }

  private getSafeguardStatus(channel:string): Observable<any> {
    let taskId: string | null;
    switch (channel) {
      case 'microsoft':
        taskId = this.msLinkTaskId;
        break;
      case 'google':
        taskId = this.googleLinkTaskId;
        break;
      case 'youtube':
        taskId = this.ytLinkTaskId;
        break;
      case 'netflix':
        taskId = this.netflixLinkTaskId;
        break;
      case 'linkedin':
        taskId = this.inLinkTaskId;
        break;
      case 'yahoo':
        taskId = this.yahooLinkTaskId;
        break;
      case 'xandr':
        taskId = this.xandrLinkTaskId;
        break;
      default:
        taskId = null;
        break;
    }
    const sessionToken = sessionStorage.getItem(channel + 'Token');
    const params = {
      task_id: taskId,
      session_token: sessionToken
    }
    const apiUrl = `${this.apiUrl}/v1/get_task_status`;
    return this.http.post<any>(apiUrl, params, this.httpOptions);
  }

  public statusPoll(channel: string): Observable<any> {
    return interval( 10000)
      .pipe(switchMap(() => this.getSafeguardStatus(channel)),
        filter(response => response.status !== 'in process'),
        takeUntil(timer(LINK_TASK_ID_EXPIRE_HOUR * 60 * 60 * 1000)),
        take(1));
  }

  createAccount(params: any, search?: string): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/create_account`;
    const createUrl = search && search.length ? `${apiUrl}/${search}` : apiUrl;
    console.log('createAccount: ', {params, createUrl});
    return this.http.post<any>(createUrl, params, this.httpOptions);
  }

  checkUserSession(params: any, channel: string): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/${channel}_platform/check_user_access`;
    return this.http.post<any>(apiUrl, params, this.httpOptions);
  }

  init(channel: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/v1/init/${channel}`);
  }

  ipValidate(channel: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/v1/ipValidate/${channel}`);
  }

  recaptchaAssessment(params: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/create_recaptcha_assessment`;
    return this.http.post<any>(apiUrl, params, this.httpOptions);
  }

  async startSafeguardTask(params: any, channel: string): Promise<void> {
    const apiUrl = `${environment.apiUrl}/v1/${channel}_platform/start_safeguard_task`;
    params.recaptchaToken = await this.executeRecaptcha('start_safeguard_task_' + channel);
    this.http.post<any>(apiUrl, params, this.httpOptions).subscribe();
  }

  accept(params: any, channel: string): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/${channel}_platform/accept`;
    return this.http.post<any>(apiUrl, params, this.httpOptions);
  }

  submitRedeemInfo(params: any): void {
    const apiUrl = `${environment.apiUrl}/v1/submitRedeemInfo`;
    this.http.post<any>(apiUrl, params, this.httpOptions).subscribe();
  }

  async verifyCode(params: any): Promise<Observable<any>> {
    const apiUrl = `${environment.apiUrl}/v1/check_twilio_code`;
    params.recaptchaToken = await this.executeRecaptcha('verify_code');
    return this.http.post<any>(apiUrl, params, this.httpOptions);
  }

  async createVerifyCode(params: any): Promise<Observable<any>> {
    const apiUrl = `${environment.apiUrl}/v1/create_check_twilio_code`;
    params.recaptchaToken = await this.executeRecaptcha('create_verify_code');
    return this.http.post<any>(apiUrl, params, this.httpOptions);
  }


  reSendCode(params: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/resend_twilio_code`;
    return this.http.post<any>(apiUrl, params, this.httpOptions);
  }

  startVerifyCode(params: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/start_twilio_code`;
    return this.http.post<any>(apiUrl, params, this.httpOptions);
  }

  executeRecaptcha(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      grecaptcha.enterprise.ready(() => {
        try {
          const token =grecaptcha.enterprise.execute('6Lf5eGMpAAAAACQW-o7MMHqBgxEn9qBuWIYtBrqQ', { action });
          resolve(token);
        } catch (error) {
          reject(error);
        }
      });
    });
  }
}
