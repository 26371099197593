import {Component, OnInit} from '@angular/core';
import {ApiCallService} from "../helpers/api-call.service";
import {lastValueFrom} from "rxjs";

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    standalone: false
})
export class AdminComponent  implements  OnInit {
  isLoggedIn = false;
  loading = false;
  saving = false;
  userName: string | undefined;
  userPassword: string | undefined;
  configurations: any[] = [];
  languages: string[] = ['en', 'de', 'fr', 'es', 'it', 'pt', 'nl', 'tc', 'sc', 'ru', 'vi', 'ar', 'tl', 'uk'];
  selectedConfiguration: any = null;
  selectedLanguage: string = 'en';

 constructor(private apiCallService: ApiCallService) {
 }
  async ngOnInit(): Promise<void> {
    const login$ = this.apiCallService.login();
    const {loggedIn, userToken, userName} = await lastValueFrom(login$);
    this.isLoggedIn = loggedIn;
    this.apiCallService.adminToken = userToken;
    this.apiCallService.adminUserName = userName;
    this.loading = true;
    const configurations$ = this.apiCallService.getConfigurations('admin');
    const {configurations} = await lastValueFrom(configurations$);
    this.configurations = configurations || [];
    this.loading = false;

  }
  login(): void {
    const params = {
      userName: this.userName,
      userPassword: this.userPassword
    };
    this.apiCallService.login(params).toPromise().then((response) => {
      this.isLoggedIn = response.loggedIn;
      this.apiCallService.adminToken = response.userToken;
      this.apiCallService.adminUserName = response.userName;
    }, (reason) => console.error({reason}));
  }

  async saveConfiguration(): Promise<void> {
   if (this.selectedConfiguration) {
     const data = { ...this.selectedConfiguration};
     const cid = data._id;
     delete data._id;
     this.saving = true;
     const configuration$ = this.apiCallService.updateConfiguration(cid, data);
     const {updated} = await lastValueFrom(configuration$);
     console.log('saveConfiguration:', updated);
     this.saving = false;
   }
  }
}
