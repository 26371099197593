{
  "Default": "Google在连接您的账户时出现了问题。我们的工程师正在努力修复，请稍后再试。",
  "Try again": "Google正在遇到连接您的账户的问题。请稍后再试或联系支持。",
  "Success": "该账户已经链接到Couponer的经理账户。链接另一个账户或创建一个新账户。",
  "Automatically": "您的MCC无法自动关联。请联系支持手动关联。",
  "Active": "您的Google广告账户未激活，请修复或创建新账户。",
  "Multiple": "您的MCC账户有多个层次结构。请联系支持手动关联。",
  "Maximum":  "Google在连接您的账户时出现了问题。我们的工程师正在努力修复，请稍后再试。",
  "Credentials": "您正在使用的凭据无法访问您的Google Ads账户。",
  "Suspended": "您的账户已经连接到一个被暂停的MCC。",
  "Oversize":  "您尝试关联的账户拥有过多的子账户。请尝试关联单独的账户。",
  "Spend": "只有在过去30天内支出大于 $1 的账户符合优惠券申请条件。请尝试关联其他账号或创建新账号。",
  "timeout": "任务超时， 请稍后再试。",
  "Verify": "您的身份认证未通过，请稍后再试或联系支持。"
}
