{
  "Default": "Google is having trouble connecting your account. Our engineers are working to fix it. Please try again later.",
  "Try again": "Google is having trouble connecting your account. Please try again later or contact support.",
  "Success": "The account is already linked to Couponer's manager account. Link another account or create a new one.",
  "Automatically": "Your MCC cannot be linked automatically. Please contact support for manual linking.",
  "Active": "Your Google Ads account is not active, fix it or create a new account.",
  "Multiple": "Your MCC account has multiple hierarchies. Please contact support for manual linking.",
  "Maximum":  "Google is having trouble connecting your account. Our engineers are working to fix it. Please try again later.",
  "Credentials": "The credentials you`re using do not have access to your Google Ads account.",
  "Suspended": "Your account is already connected to a suspended MCC.",
  "Oversize":  "The MCC you’re attempting to connect is too large to benefit from the coupons. Please consider linking individual accounts instead.",
  "Spend": "Account must have recorded spending of $1 in the last 30 days to benefit from the coupon. Please try linking a different account or create a new one.",
  "timeout": "Task timeout. Please try again later.",
  "Verify": "We meet error in the identity verification process. Please try again later or contact support."
}
