import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceService } from '../../helpers/data.service';

@Component({
    selector: 'app-all-coupons',
    templateUrl: './all-coupons.component.html',
    styleUrl: './all-coupons.component.scss',
    standalone: false
})
export class AllCouponsComponent {
  couponList:any =  []
  showArrList:any = [];
  constructor(private router: Router, private data:DataServiceService){
    this.couponList = this.data.couponArray;
  }

  createShowList() {
    //this.sortCouponsrrByID(this.couponList);
    this.couponList = this.data.couponArray;
    if (this.couponList && this.couponList.length > 0 && this.showArrList.length === 0) {
      for(let i=0; i<12; i++) {
        if (this.couponList[i]) {
          this.showArrList.push(this.couponList[i]);
        }
      }
    }
    return this.showArrList;
  }

  sortCouponsrrByID(couponsArray: any) {
    let locationsByID = [1,7,11,4,5,6,2,8,9,10,3,12,13,14,15,16,17,18,19,21];
    let arr: any[] = [];
    for (var index in locationsByID) {
      let obj = {};
      let id = locationsByID[index].toString() || '';
      obj = couponsArray.find((obj: any) => obj.id === id);
      if (obj) {
        arr.push(obj);
      }
    }
    this.couponList = arr;
  }

  showMoreCoupons() {
    let arrShowSize = this.showArrList.length;
    let arrMainSize = this.couponList.length;
    let num = arrMainSize - arrShowSize;
    for(let i=0; i<num; i++) {
      if (this.couponList[arrShowSize + i]) {
        this.showArrList.push(this.couponList[arrShowSize + i]);
      }
    }
    //let num = arrMainSize - arrShowSize;
    //if (num >= 8) {
     // for(let i=0; i<8; i++) {
        //if (this.couponList[arrShowSize + i]) {
         // this.showArrList.push(this.couponList[arrShowSize + i]);
        //}
     // }
    //}
    //if (num < 8 && num > 0) {
     // let index = this.couponList.length - num;
     // for(let i=index; i<(index + 4); i++) {
       // if (this.couponList[i]) {
        //  this.showArrList.push(this.couponList[i]);
        //}
     // }
    //}
  }

  showLessCoupons() {
    //let arrShowSize = this.showArrList.length;
    //for(let i=0; i<8; i++) {
      //if (this.showArrList[arrShowSize - 1 - i]) {
      //  this.showArrList.pop();
      //}
    //}
    if(this.couponList && this.couponList.length > 11) {
      this.showArrList = [];
      for(let i=0; i<12; i++) {
        if (this.showArrList[i]) {
          this.showArrList.push();
        }
      }
    }
  }

  goToCoupon() {
    let obj = this.data.selectedCoupon;
    if (obj && obj.type) {
      if (obj.link) {
        this.router.navigate(['/' + obj.link]);
      } else {
        this.router.navigate(['/' + obj.type]);
      }
    }
  }

}
