import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
    standalone: false
})
export class VideoPlayerComponent {
  @Input() videoPath: string| undefined = '';
  @Input() type: string| undefined = '';
  urlSafe: SafeResourceUrl | undefined;

  constructor(public sanitizer: DomSanitizer) {
  }

  getHeight() {
    if (this.videoPath) {
      let element= document.getElementById("videoPlayer")?.offsetWidth;
      let num = 390/220;
      if (element && num) {
        if (element <= 450) {
          return Math.floor((element/num));
        } 
        if (element > 450 && element < 715 && this.type === 'microsoft') {
          return Math.floor((element/num) - 1);
        } else {
          return Math.floor((element/num) - 1);
        }
      }
      return '';
    }
    return '';
  }

  ngOnInit() {
    if (this.videoPath) {
      this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.videoPath);
    }
  }

}
