<div class="coupon-layout google-coupon yh-layout-addition fixed-header">
    <app-header></app-header>
    <div class="main-content">
      <div class="adcore-logo"><a href="https://www.adcore.com" target="_blank"><i></i></a></div>
      <a class="yh-tag logo-via-mic"><i></i><span>via</span><i></i></a>
      <div class="content">
          <span>Get <span class="sign bold">$</span><span class="bold">500</span> Yahoo Advertising*</span> &nbsp;
          <span class="last">2024 coupon in two easy steps</span>
      </div>
      <div class="google-eligible-btn"><button (click)="goToEligibleLink('microsoft')">For eligible accounts only</button></div>
      <div class="link-acc">
        <app-link-steps [step1Text]="'Link your'" [step1SecText]="'Microsoft Ads account'" [step2Text]="'Redeem your'" [step2SecText]="'$500 coupon'" [stepMiddleText]="'Checking your eligibility...'" [showMiddleStep]="loading" [accountLinked]="loading"></app-link-steps>
        <div class="buttons" [ngClass]="loading ? 'loader-on' : ''">
          <div class="loader-box acc" *ngIf="loading">
            <div class="acc-loader-blue"></div>
          </div>
            <div class="additional-text">
                <div>*This coupon is for Microsoft Advertising to target</div>
                <div>Yahoo audience using the Yahoo Bing Network.</div>
            </div>
            <div *ngIf="!loading">
                <div>
                  <p-button styleClass="link-btn" label="Link your Microsoft Ads account" (onClick)="openRecaptcha('link')"></p-button>
                </div>
                <div class="text">Or</div>
              <div><a class="create-btn" (click)="openRecaptcha('create')">Create new account</a></div>
            </div>
        </div>
      </div>
      <app-metrics [merticData]="metricData"></app-metrics>
      <app-video-player videoPath="https://www.youtube.com/embed/VSJ7MS8dLYQ"></app-video-player>
      <app-powered [lang]="'en'" (linkBtnEvent)="openRecaptcha('link')" [isFirstStep]="true" [type]="'microsoft'"></app-powered>
      <div *ngIf="!showCsData">
        <app-questions [listItemsType]="'microsoft'" [title]="'Q&A'" [selectedAccordinAIndex]="openTabIndex" (changeSelectedTabIndex)="changeSelectedTabIndex($event)"></app-questions>    </div>
      <app-footer [lang]="'en'"></app-footer>
    </div>
  </div>
  <p-dialog [(visible)]="showDialog" styleClass="create-acc-dialog" [modal]="true" [blockScroll]="true">
    <ng-template pTemplate="header">
      {{dialogHeader}}
      <i class="success-icon" *ngIf="dialogHeader === 'Account created successfully'"></i>
    </ng-template>
    <p>{{dialogBody}}</p>
    <ng-template pTemplate="footer">
      <p-button *ngIf="redeemButton" label="Got it" [disabled]="loading" (onClick)="got_it('create')" styleClass="primary-btn"></p-button>
    </ng-template>
  </p-dialog>
<p-toast key="msgTemplate" position="center" (onClose)="close($event)">
  <ng-template let-message pTemplate="message">
    <app-msg-template [message]="message" (confirm)="got_it('link')">
      <div><p>{{message.detail}}</p></div>
    </app-msg-template>
  </ng-template>
</p-toast>
