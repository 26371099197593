import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {WindowRefService} from "../../../helpers/windowref.service";


@Component({
    selector: 'app-create-account-in-mb',
    templateUrl: './create-account-in-mb.html',
    styleUrls: ['./create-account-in-mb.scss'],
    standalone: false
})

export class CreateAccountInMb implements OnInit {

  page: string | undefined;

  constructor(public dialogConfig: DynamicDialogConfig, private winRef: WindowRefService) { }

  async ngOnInit(): Promise<void> {
    this.page = this.dialogConfig?.data;
  }

  goToMediaBlast() {
    this.winRef.navigate("https://www.adcore.com/technologies/media-blast/?utm_source=couponer&utm_medium=referral&campaign=create_account");
  }
}
