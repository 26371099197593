import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {lastValueFrom, Subject} from 'rxjs';
import {takeUntil} from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {WindowRefService} from "../../helpers/windowref.service";
import translation from "../../google-coupon/google-coupon-step1/messageTranslation.json";
import {MessageService} from 'primeng/api';
import { ConfigService } from '../../helpers/config.service';
import { DataServiceService } from '../../helpers/data.service';
import {ApiCallService} from "../../helpers/api-call.service";
import {NavigationService} from "../../helpers/navigation.service";

@Component({
    selector: 'app-xandr-coupon-step2',
    templateUrl: './xandr-coupon-step2.component.html',
    styleUrl: './xandr-coupon-step2.component.scss',
    standalone: false
})
export class XandrCouponStep2Component implements OnInit, OnDestroy {
  componentDestroyed: Subject<boolean> = new Subject();
  loading: boolean = false;
  geo: string = 'Global';
  openTabIndex:any = undefined;
  metricData:any = {
    link: {
      amount: '3,000',
      text: 'Accounts Linked',
    },
    redeemed: {
      amount: '2,250',
      text: 'Coupons Redeemed',
    },
    saved: {
      amount: '$955,000',
      text: 'Saved',
    }
  };
  page = 'xandr';
  csData:any = {};
  showCsData:boolean = true;
  constructor(private router: Router, private winRef: WindowRefService,
              private messageService: MessageService, public data:DataServiceService, public cs: ConfigService,
              private apiCallService: ApiCallService, private navigationService: NavigationService,) {
    this.data.setHeaderMenuSelection('9');
  }

  ngOnInit(): void {
    this.winRef.reportGtagEventNew('pageViewStep2');
    this.navigationService.canNavigateToStep3Xandr = false;
    document.body.scrollTop = 0;
    this.getCsData();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  async getCsData(): Promise<void> {
    this.csData = {};
    this.showCsData = true;
    await this.cs.getConfigurations('xandr', 'en');
    this.data.setCsObj(this.cs);
    this.csData = this.cs;
    setTimeout(() => {
      this.showCsData = false;
    }, 100);
  }

  goToEligibleLink(channel:string) {
    this.data.goToEligibleLink(channel);
   }
   
  changeSelectedTabIndex(e:any) {
    if (e) {
      this.openTabIndex =e.index;
    }
  }

  async RedeemCoupon() {
    const info = sessionStorage.getItem('xandrInfo');
    const sessionToken = sessionStorage.getItem('xandrToken');
    const passedList = sessionStorage.getItem("xandrPassedList")
    if (info && sessionToken && passedList) {
      const infoJson = JSON.parse(info);
      const refreshToken = infoJson.refresh_token;
      const customerIdList = JSON.parse(passedList);
      if (refreshToken && customerIdList && customerIdList.length) {
        this.loading = true;
        const params = {
          refresh_token: refreshToken,
          customer_id_list: customerIdList,
          session_token: sessionToken,
          geo: this.geo,
          utm_source: infoJson.utm_source,
          utm_medium: infoJson.utm_medium,
          page: this.page
        };
        const accept$ = this.apiCallService.accept(params, 'microsoft');
        const response = await lastValueFrom(accept$);
        this.loading = false;
        if (response.result == 'success'){
          sessionStorage.removeItem("xandrInfo");
          sessionStorage.removeItem("xandrToken");
          sessionStorage.removeItem("xandrPassedList");
          this.navigationService.canNavigateToStep3Xandr = true;
          this.router.navigate(['/xndr/step3']);
        } else {
          this.messageService.add({
            key: 'msgTemplate',
            severity: 'error',
            summary: 'We apologize for the inconvenience.',
            detail: "Microsoft is having trouble connecting your account. Please try again later or contact couponer@adcore.com",
            closable: true,
            sticky: true
          })
        }
      }
    }
  }

  got_it(): void {
    this.navigationService.canNavigateToStep3Xandr = true;
    this.router.navigate(['/xndr/step3']);
  }
}

