import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {lastValueFrom, Subject, timer} from "rxjs";
import {SocialAuthService, SocialUser} from "@abacritt/angularx-social-login";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ApiCallService} from "../../helpers/api-call.service";
import {WindowRefService} from "../../helpers/windowref.service";
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-verify-code',
    templateUrl: './verify-code.component.html',
    styleUrl: './verify-code.component.scss',
    standalone: false
})
export class VerifyCodeComponent implements OnInit, OnDestroy {
  componentDestroyed: Subject<boolean> = new Subject();
  codeError = false;
  inProcess = false;
  resendTimes = 0;
  countdown = 30;
  isResendDisabled = false;
  code: string | null = null;
  verifyInfo: any = {};
  showResentResult = false;
  resentResult = 'An error occur when resending the verification code!';

  constructor(private authService: SocialAuthService, public ref: DynamicDialogRef,
              private apiCallService: ApiCallService, private messageService: MessageService,
              public dialogConfig: DynamicDialogConfig, private winRef: WindowRefService) { }

  ngOnInit(): void {
    this.verifyInfo = this.dialogConfig?.data;
    this.startCountdown(30);
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  onCodeChange(): void {
    this.codeError = false;
    this.showResentResult = false;
  }

  async submit(){
    if (this.code?.length !== 6){
      this.codeError = true;
    } else {
      this.inProcess = true;
      const params = {
        ...this.verifyInfo,
        code: this.code
      }
      const verifyCode$ = await this.apiCallService.verifyCode(params);
      const response = await lastValueFrom(verifyCode$);
      if (response === 'approved') {
        this.ref.close(params);
      } else {
        this.codeError = true;
      }
      this.inProcess = false;
    }
  }

  async send2faRequest() {
    const params = {
      verifyInfo: this.verifyInfo,
      channel: 'email'
    }
    this.inProcess = true;
    this.codeError = false;
    const sendCode$ = this.apiCallService.reSendCode(params);
    const response = await lastValueFrom(sendCode$);
    if (response === 'success') {
      console.log('Resend success.')
      this.resentResult = 'A code has been resent to your email!';
      this.showResentResult = true;
      // this.messageService.add({
      //   key: '2faMessage',
      //   severity: 'success',
      //   closable: false,
      //   detail: 'A code has been resent to your email!'});
      this.resendTimes++;
    } else {
      console.log('Resend error.')
      this.resentResult = 'An error occur when resending the verification code!';
      this.showResentResult = true;
      // this.messageService.add({
      //   key: '2faMessage',
      //   severity: 'error',
      //   closable: false,
      //   detail: 'An error occur when resending the verification code!'});
    }
    this.inProcess = false;
    this.startCountdown(30);
  }

  startCountdown(seconds: number): void {
    this.isResendDisabled = true;
    this.countdown = seconds;
    const countdownTimer = timer(0, 1000).subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.isResendDisabled = false;
        countdownTimer.unsubscribe(); // Stop the timer
      }
    });
  }

}
