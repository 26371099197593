<div class="coupon-layout fixed-header">
    <app-header></app-header>
    <div class="main-content">
        <div>
            <div>
              <div class="coupon-layout google-coupon yh-layout-addition">
                <div class="show-coupon">
                  <div class="confetti-cover" *ngIf="showConfetti"></div>
                  <a class="yh-tag logo-via-mic"><i></i><span>via</span><i></i></a>
                  <div class="content">
                    <div *ngIf="action === 'link'">
                      <span>Coupon request successfully submitted!</span>
                    </div>
                    <div *ngIf="action === 'create'">
                      <span>Account creation request successfully submitted!</span>
                    </div>
                  </div>
                  <div class="video-mobile-view">
                    <app-ad-video [showVideo]="true" title="Where do I see my coupon?" [isMobile]="true" videoName="google-video"></app-ad-video>
                  </div>
                  <div class="video-desk-view">
                    <app-ad-video [showVideo]="showVideo" title="Where do I see my coupon?" [isMobile]="false" videoName="google-video"></app-ad-video>
                  </div>
                  <div class="google-ad-text">
                    <div *ngIf="action === 'link'">
                      If your account is eligible, it may take up-to 35 days for the coupon to be applied by Google.<br class="space"> For more information on account eligibility and promotional codes policy, please refer to Google’s <a href="https://support.google.com/adspolicy/answer/1396257?hl=en" target="_blank"><span class="desktop-label">terms and conditions</span><span class="mobile-label">T&Cs</span></a>.
                    </div>
                    <div *ngIf="action === 'create'">
                      Thank you for requesting an account with Couponer! If your domain meets the qualifications, we will notify you via email. Upon approval, it may take up to 35 days for Google to apply the coupon. For more information on account eligibility and promotional code policies, please refer to Google's  <a href="https://support.google.com/adspolicy/answer/1396257?hl=en" target="_blank"><span class="desktop-label">terms and conditions</span><span class="mobile-label">T&Cs</span></a>.
                    </div>
                  </div>
                  <div class="buttons">
                    <div class="get-coupon-btn"><a href="https://couponer.app/ms" target="_blank"><span class="icon-box"><i></i></span><span class="text">Get $500 Microsoft Ads coupon</span></a></div>
                    <span class="or desktop">Or</span>
                    <div class="another-acc-btn"><p-button styleClass="link-btn" label="Link another Microsoft account" (onClick)="linkAccount()"></p-button></div>
                    <div class="or mobile">Or</div>
                  </div>
                </div>
              </div>
              <div class="popular-coupon">
                <h3>Explore popular coupons</h3>
                <div class="coupon-box-large-layout">
                  <app-coupon-box [listItem]="this.data.popularCouponItem" [isSecondaryLayout]="true" (redeemEvent)="this.data.goToCoupon('3')"></app-coupon-box>
                </div>
              </div>
              <h3 class="explore-title">Explore all coupons</h3>
                <div class="coupon-layout-home-body">
                    <app-all-coupons></app-all-coupons>
                </div>
            </div>
        </div>
        <app-footer [lang]="'en'"></app-footer>
    </div>
</div>


