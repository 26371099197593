import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { CouponItem } from './models';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  selectedCouponObj:any = {};
  couponFullName:string = '';
  selectedCouponCsObj:any = {};
  headerMenuSelect:any | undefined= {};
  couponLastStep:boolean = false;
  couponsArray:CouponItem[] = [];
  mainCouponArr:any = [
    {
      name: 'home'
    },
    {
      name: 'google',
      cuoponsArr: [
        {
          id: '1',
          type: 'gl',
          link:'g',
          title:'',
          description:'Google Ads coupon',
          price:'$500',
          tooltip:'Spend $200 and get $500 coupon for advertising in Google Ads',
          isPrimary:true
        },
      ]
    },
    {
      name: 'microsoft',
      cuoponsArr: [
        {
          id: '2',
          type: 'mc',
          link:'ms',
          title:'',
          description:'Microsoft Advertising coupon',
          price:'$500',
          tooltip:'Spend $200 and get $500 coupon for advertising in Microsoft Ads',
          isPrimary:true
        },
      ]
    },
    {
      name: 'criteo',
      cuoponsArr: [
        {
          id: '3',
          type: 'cr',
          title:'',
          description:'',
          price:'',
          tooltip:'',
        },
        {
          id: '4',
          type: 'cr',
          title:'',
          description:'',
          price:'',
          tooltip:'',
        },
        {
          id: '5',
          type: 'cr',
          title:'',
          description:'',
          price:'',
          tooltip:'',
          isPrimary:true
        },
      ]
    },
    {
      name: 'yandex',
      cuoponsArr: [
        {
          id: '6',
          type: 'y',
          title:'',
          description:'',
          price:'',
          tooltip:'',
        },
        {
          id: '7',
          type: 'y',
          title:'',
          description:'',
          price:'',
          tooltip:'',
        },
        {
          id: '8',
          type: 'y',
          title:'',
          description:'',
          price:'',
          tooltip:'',
          isPrimary:true
        },
      ]
    },
    // {
    //   name: 'rtbhouse',
    //   cuoponsArr: [
    //     {
    //       id: '9',
    //       type: 'rtb',
    //       title:'',
    //       description:'',
    //       price:'',
    //       tooltip:'',
    //     },
    //     {
    //       id: '10',
    //       type: 'rtb',
    //       title:'',
    //       description:'',
    //       price:'',
    //       tooltip:'',
    //     },
    //     {
    //       id: '11',
    //       type: 'rtb',
    //       title:'',
    //       description:'',
    //       price:'',
    //       tooltip:'',
    //       isPrimary:true
    //     },
    //   ]
    // },
    {
      name: 'youtube',
      cuoponsArr: [
        {
          id: '12',
          type: 'yt',
          link:'yt',
          title:'',
          description:'YouTube coupon',
          price:'$500',
          tooltip:'Spend $200 and get $500 coupon for advertising in YouTube ',
          isPrimary:true
        }
      ]
    },
    {
      name: 'xandr',
      cuoponsArr: [
        {
          id: '14',
          type: 'xndr',
          title:'',
          description:'',
          price:'',
          tooltip:'',
          isPrimary:true
        },
        //{
         // id: '15',
         // type: 'xndr',
         // title:'',
         // description:'',
         // price:'',
         // tooltip:'',
        //},
        //{
         // id: '16',
        //  type: 'xndr',
        //  title:'',
        //  description:'',
        //  price:'',
         // tooltip:'',
        //  isPrimary:true
       // }
      ]
    },
    {
      name: 'mastercard',
      cuoponsArr: [
        {
          id: '17',
          type: 'mcard'
        },
        {
          id: '18',
          type: 'mcard',
          isPrimary:true
        }
      ]
    },
    {
      name: 'dv360',
      cuoponsArr: [
        {
          id: '19',
          type: 'dv'
        },
        {
          id: '20',
          type: 'dv'
        },
        {
          id: '21',
          type: 'dv',
          isPrimary:true
        }
      ]
    },
    {
      name: 'netflix',
      cuoponsArr: [
        {
          id: '22',
          type: 'n',
          isPrimary:true
        }
      ]
    },
    {
      name: 'linkedin',
      cuoponsArr: [
        {
          id: '23',
          type: 'in',
          isPrimary:true
        }
      ]
    },
    {
      name: 'yahoo',
      cuoponsArr: [
        {
          id: '24',
          type: 'yh',
          isPrimary:true
        }
      ]
    }
  ];
  headerMenuItems: MenuItem[] | undefined = [
    {
      id: '1',
      label: 'All coupons',
      icon: 'pi all',
      styleClass: 'home-palette coupon-selector-item',
      urlName: 'home',
    },
    {
      id: '2',
      label: 'Google Ads',
      styleClass: 'gl-palette coupon-selector-item',
      icon: 'pi gl',
      urlName: 'g'
    },
    {
      id: '3',
      label: 'Microsoft Advertising',
      styleClass: 'mc-palette coupon-selector-item',
      icon: 'pi mc',
      urlName: 'ms'
    },
    // {
    //   id: '4',
    //   label: 'RTB HOUSE',
    //   styleClass: 'rtb-palette coupon-selector-item',
    //   icon: 'pi rtb',
    //   urlName: 'rtb'
    // },
    {
      id: '5',
      label: 'DV360',
      styleClass: 'dv-palette coupon-selector-item',
      icon: 'pi dv',
      urlName: 'dv'
    },
    {
      id: '6',
        label: 'Yandex',
        styleClass: 'y-palette coupon-selector-item',
        icon: 'pi yandex',
        urlName: 'y'
    },
    {
      id: '7',
      label: 'Criteo',
      styleClass: 'cr-palette coupon-selector-item',
      icon: 'pi cr',
      urlName: 'cr'
    },
    {
      id: '8',
      label: 'MasterCard',
      styleClass: 'mcard-palette coupon-selector-item',
      icon: 'pi mcard',
      urlName: 'mcard'
    },
    {
      id: '9',
      label: 'Xandr',
      styleClass: 'xndr-palette coupon-selector-item',
      icon: 'pi xndr',
      urlName: 'xndr'
    },
    {
      id: '10',
      label: 'Youtube',
      styleClass: 'yt-palette coupon-selector-item',
      icon: 'pi yt',
      urlName: 'yt'
    },
    {
      id: '11',
      label: 'Netflix',
      styleClass: 'n-palette coupon-selector-item',
      icon: 'pi n',
      urlName: 'n'
    },
    {
      id: '12',
      label: 'LinkedIn',
      styleClass: 'in-palette coupon-selector-item',
      icon: 'pi in',
      urlName: 'in'
    },
    {
      id: '13',
      label: 'Yahoo Advertising',
      styleClass: 'yh-palette coupon-selector-item',
      icon: 'pi yh',
      urlName: 'yh'
    },
  ];
  popularCouponItem:any = {
    id: '2',
    type: 'mc',
    link:'ms',
    title:'',
    description:'Microsoft Advertising coupon',
    price:'$500',
    tooltip:'Spend $200 and get $500 coupon for advertising in Microsoft Ads',
    isPrimary:true
  }
  constructor(private router: Router) {
  }

  createArr(arr:any) {
    let couponName = '';
    let tmpArr = [];
    if (arr && this.mainCouponArr) {
      for (var item of this.mainCouponArr) {
        couponName = '';
        tmpArr = [];
        couponName = item.name;
        tmpArr = arr.filter((obj: any) => obj.coupon === couponName);
        if (couponName !== 'google' && couponName !== 'microsoft' && couponName !== 'youtube' && couponName !== 'home') {
          if (item.cuoponsArr && tmpArr && tmpArr.length > 0) {
            for (var index in item.cuoponsArr) {
              if(item.cuoponsArr[index] && tmpArr[index]) {
                item.cuoponsArr[index].title = tmpArr[index].description || '';
                item.cuoponsArr[index].price = tmpArr[index].title || '';
                item.cuoponsArr[index].tooltip = tmpArr[index].tooltip || '';
                this.couponsArray.push(item.cuoponsArr[index]);
              }
            }
           }
        } else {
          if (couponName !== 'home') {
            let obj = this.mainCouponArr.find((obj: any) => obj.name === couponName);
            if (obj && obj.cuoponsArr) {
              for (var item of obj.cuoponsArr) {
                this.couponsArray.push(item);
              }
            }
           }
        }
      }
      this.sortCouponsrrByID(this.couponsArray);
    }
  }

  sortCouponsrrByID(couponsArray: any) {
    let locationsByID = [1,2,23,22,24,14,3,6,17,19,1,2,12,4,7,18,1,2,5,20,8,21];
    let arr: any[] = [];
    for (var index in locationsByID) {
      let obj = {};
      let id = locationsByID[index].toString() || '';
      obj = couponsArray.find((obj: any) => obj.id === id);
      if (obj) {
        arr.push(obj);
      }
    }
    this.couponsArray = arr;
  }

  goToCoupon(id:string):void {
    if (this.headerMenuItems) {
      this.headerMenuSelect = this.headerMenuItems.find((obj: any) => obj.id === id);
      if (this.headerMenuSelect) {
        this.changeSelectedCoupon({});
        this.router.navigate(['/' + this.headerMenuSelect.urlName]);
      }
    }
  }

  get couponArray(): any {
    return this.couponsArray;
  }

   changeSelectedCoupon(obj:any) {
    this.selectedCouponObj = obj;
    if (this.selectedCouponObj && this.selectedCouponObj.type) {
      this.setCouponNameByType(this.selectedCouponObj.type);
    }
  }

  get selectedCoupon() {
    return this.selectedCouponObj;
  }
   setHeaderMenuSelection(id:string) {
    if (this.headerMenuItems) this.headerMenuSelect = this.headerMenuItems.find((obj: any) => obj.id === id);
  }

  get headerMenuSelectionObj() {
    return this.headerMenuSelect;
  }

  get headerMenuListItems() {
    return this.headerMenuItems;
  }

  get isCouponLastStep() {
    return this.couponLastStep;
  }

  setIsCouponLastStep(isLast:boolean) {
    this.couponLastStep = isLast;
  }

  setCsObj(obj:any) {
    this.selectedCouponCsObj = obj;
    if (this.couponsArray && this.couponsArray.length === 0 && obj) {
      this.createArr(this.selectedCouponCsObj.badges);
    }
  }

  get csObj() {
    return this.selectedCouponCsObj;
  }

  setCouponNameByType(type:string) {
    this.couponFullName = '';
    switch (type) {
      case 'gl':
        this.couponFullName = 'Google';
        break;
      case 'mc':
        this.couponFullName = 'Microsoft';
        break;
      case 'cr':
        this.couponFullName = 'Criteo';
        break;
      case 'y':
        this.couponFullName = 'Yandex';
        break;
      case 'rtb':
        this.couponFullName = 'RTB HOUSE';
        break;
      case 'yt':
        this.couponFullName = 'Youtube';
        break;
      case 'xndr':
        this.couponFullName = 'Xandr';
        break;
      case 'mcard':
        this.couponFullName = 'MasterCard';
        break;
      case 'dv':
        this.couponFullName = 'DV360';
        break;
      case 'n':
      this.couponFullName = 'Netflix';
      break;
      case 'in':
      this.couponFullName = 'Linkedin';
      break;
      case 'yh':
      this.couponFullName = 'Yahoo';
      break;
    }
  }

  goToEligibleLink(channel:string) {
    switch (channel) {
      case 'microsoft':
        window.open('https://help.ads.microsoft.com/apex/index/3/en/50829', '_blank');
        break;
      case 'google':
        window.open('https://support.google.com/adspolicy/answer/1396257?hl=en', '_blank');
        break;
      default: 
        window.open('', '_blank');
      break;
    }
  }

}
