{
  "timezones": [
    {"value": "AbuDhabiMuscat", "label": "(GMT+0400) Abu Dhabi, Muscat"},
    {"value": "Adelaide", "label": "(GMT+0930) Adelaide"},
    {"value": "Alaska", "label": "(GMT-0900) Alaska"},
    {"value": "AlmatyNovosibirsk", "label": "(GMT+0600) Almaty, Novosibirsk"},
    {"value": "AmsterdamBerlinBernRomeStockholmVienna", "label": "(GMT+0100) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"},
    {"value": "Arizona", "label": "(GMT-0700) Arizona"},
    {"value": "AstanaDhaka", "label": "(GMT+0600) Astana, Dhaka"},
    {"value": "AthensBuckarestIstanbul", "label": "(GMT+02:00) Athens, Istanbul, Beirut, and Minsk"},
    {"value": "AtlanticTimeCanada", "label": "(GMT-0400) Atlantic Time and Canada"},
    {"value": "AucklandWellington", "label": "(GMT+1200) Auckland and Wellington, New Zealand"},
    {"value": "Azores", "label": "(GMT-0100) Azores"},
    {"value": "Baghdad", "label": "(GMT+0300) Baghdad"},
    {"value": "BakuTbilisiYerevan", "label": "(GMT+0400) Baku, Tbilisi, and Yerevan"},
    {"value": "BangkokHanoiJakarta", "label": "(GMT+0700) Bangkok, Hanoi, and Jakarta"},
    {"value": "BeijingChongqingHongKongUrumqi", "label": "(GMT+0800) Beijing, Chongqing, Hong Kong SAR, and Urumqi"},
    {"value": "BelgradeBratislavaBudapestLjubljanaPrague", "label": "(GMT+0100) Belgrade, Bratislava, Budapest, Ljubljana, and Prague"},
    {"value": "BogotaLimaQuito", "label": "(GMT-0500) Bogota, Lima, and Quito"},
    {"value": "Brasilia", "label": "(GMT-0300) Brasilia"},
    {"value": "Brisbane", "label": "(GMT+1000) Brisbane"},
    {"value": "BrusselsCopenhagenMadridParis", "label": "(GMT+0100) Brussels, Copenhagen, Madrid, and Paris"},
    {"value": "Bucharest", "label": "(GMT+0200) Bucharest"},
    {"value": "BuenosAiresGeorgetown", "label": "(GMT-0300) Buenos Aires and Georgetown"},
    {"value": "Cairo", "label": "(GMT+0200) Cairo"},
    {"value": "CanberraMelbourneSydney", "label": "(GMT+1000) Canberra, Melbourne, and Sydney"},
    {"value": "CapeVerdeIsland", "label": "(GMT-0100) Cape Verde Island"},
    {"value": "CaracasLaPaz", "label": "(GMT-0400) Caracas and La Paz (Bolivia)"},
    {"value": "CasablancaMonrovia", "label": "(GMT+0000) Casablanca and Monrovia"},
    {"value": "CentralAmerica", "label": "(GMT-0600) Central America"},
    {"value": "CentralTimeUSCanada", "label": "(GMT-0600) Central Time U.S. and Canada"},
    {"value": "ChennaiKolkataMumbaiNewDelhi", "label": "(GMT+0530) Chennai, Kolkata, Mumbai, and New Delhi"},
    {"value": "ChihuahuaLaPazMazatlan", "label": "(GMT-0700) Chihuahua, La Paz (Mexico), and Mazatlan"},
    {"value": "Darwin", "label": "(GMT+0930) Darwin"},
    {"value": "EasternTimeUSCanada", "label": "(GMT-0500) Eastern Time U.S. and Canada"},
    {"value": "Ekaterinburg", "label": "(GMT+0500) Ekaterinburg"},
    {"value": "FijiKamchatkaMarshallIsland", "label": "(GMT+12:00) Fiji, Kamchatka, and Marshall Island"},
    {"value": "Greenland", "label": "(GMT-0300) Greenland"},
    {"value": "GreenwichMeanTimeDublinEdinburghLisbonLondon", "label": "(GMT+0000) Greenwich Mean Time, Dublin, Edinburgh, Lisbon, and London"},
    {"value": "GuadalajaraMexicoCityMonterrey", "label": "(GMT-0600) Guadalajara, Mexico City, and Monterrey"},
    {"value": "GuamPortMoresby", "label": "(GMT+1000) Guam and Port Moresby"},
    {"value": "HararePretoria", "label": "(GMT+0200) Harare and Pretoria"},
    {"value": "Hawaii", "label": "(GMT-1000) Hawaii"},
    {"value": "HelsinkiKyivRigaSofiaTallinnVilnius", "label": "(GMT+0200) Helsinki, Kyiv, Riga, Sofia, Tallinn, and Vilnius"},
    {"value": "Hobart", "label": "(GMT+1000) Hobart"},
    {"value": "IndianaEast", "label": "(GMT-0500) Indiana (East)"},
    {"value": "InternationalDateLineWest", "label": "(GMT-1200) International Date Line (West)"},
    {"value": "IrkutskUlaanBataar", "label": "(GMT+0800) Irkutsk and Ulaanbaatar"},
    {"value": "IslandamabadKarachiTashkent", "label": "(GMT+0500) Islamabad, Karachi, and Tashkent"},
    {"value": "Jerusalem", "label": "(GMT+0200) Jerusalem"},
    {"value": "Kabul", "label": "(GMT+0430) Kabul"},
    {"value": "Kathmandu", "label": "(GMT+0545) Kathmandu"},
    {"value": "Krasnoyarsk", "label": "(GMT+0700) Krasnoyarsk"},
    {"value": "KualaLumpurSingapore", "label": "(GMT+0800) Kuala Lumpur and Singapore"},
    {"value": "KuwaitRiyadh", "label": "(GMT+0300) Kuwait and Riyadh"},
    {"value": "MidAtlantic", "label": "(GMT-0200) Mid-Atlantic"},
    {"value": "MidwayIslandAndSamoa", "label": "(GMT-1100) Midway Island and Samoa"},
    {"value": "MoscowStPetersburgVolgograd", "label": "(GMT+0300) Moscow, St Petersburg, and Volgograd"},
    {"value": "MountainTimeUSCanada", "label": "(GMT-0700) Mountain Time U.S. and Canada"},
    {"value": "Nairobi", "label": "(GMT+0300) Nairobi"},
    {"value": "Newfoundland", "label": "(GMT-0330) Newfoundland"},
    {"value": "Nukualofa", "label": "(GMT+1300) Nuku'alofa"},
    {"value": "OsakaSapporoTokyo", "label": "(GMT+0900) Osaka, Sapporo, and Tokyo"},
    {"value": "PacificTimeUSCanadaTijuana", "label": "(GMT-0800) Pacific Time, U.S. and Canada, and Tijuana"},
    {"value": "Perth", "label": "(GMT+0800) Perth"},
    {"value": "Rangoon", "label": "(GMT+0630) Rangoon"},
    {"value": "Santiago", "label": "(GMT-0400) Santiago"},
    {"value": "SarajevoSkopjeWarsawZagreb", "label": "(GMT+0100) Sarajevo, Skopje, Warsaw, and Zagreb"},
    {"value": "Saskatchewan", "label": "(GMT-0600) Saskatchewan"},
    {"value": "Seoul", "label": "(GMT+0900) Seoul"},
    {"value": "SolomonIslandNewCaledonia", "label": "(GMT+1100) Solomon Islands and New Caledonia"},
    {"value": "SriJayawardenepura", "label": "(GMT+0500) Sri Jayawardenepura"},
    {"value": "Taipei", "label": "(GMT+0800) Taipei"},
    {"value": "Tehran", "label": "(GMT+0330) Tehran"},
    {"value": "Vladivostok", "label": "(GMT+1000) Vladivostok"},
    {"value": "WestCentralAfrica", "label": "(GMT+0100) West Central Africa"},
    {"value": "Yakutsk", "label": "(GMT+0900) Yakutsk"}
  ],
  "currencyCodes": ["AED", "AMD", "ARS", "AUD", "AZM", "BGL", "BHD", "BND", "BOB", "BRL", "BYB", "BZD", "CAD", "CHF", "CLP", "CNY", "COP", "CRC", "CZK", "DKK", "DOP", "DZD", "EEK", "EGP", "EUR", "GBP", "GEL", "GTQ", "HKD", "HNL", "HRK", "HUF", "IDR", "ILS", "INR", "IQD", "IRR", "ISK", "JMD", "JOD", "JPY", "KES", "KGS", "KRW", "KWD", "KZT", "LBP", "LTL", "LVL", "LYD", "MAD", "MKD", "MNT", "MOP", "MVR", "MXN", "MYR", "NGN", "NIO", "NOK", "NZD", "OMR", "PAB", "PEN", "PHP", "PKR", "PLN", "PYG", "QAR", "ROL", "RUR", "SAR", "SEK", "SGD", "SIT", "SKK", "SYP", "THB", "TND", "TRY", "TTD", "TWD", "UAH", "USD", "UYU", "UZS", "VEF", "VND", "YER", "YUN", "ZAR", "ZWD"],
  "countries": [
    {"value": "AF", "label": "Afghanistan"},
    {"value": "AL", "label": "Albania"},
    {"value": "DZ", "label": "Algeria"},
    {"value": "AS", "label": "American Samoa"},
    {"value": "AD", "label": "Andorra"},
    {"value": "AO", "label": "Angola"},
    {"value": "AI", "label": "Anguilla"},
    {"value": "AQ", "label": "Antarctica"},
    {"value": "AG", "label": "Antigua and Barbuda"},
    {"value": "AR", "label": "Argentina"},
    {"value": "AM", "label": "Armenia"},
    {"value": "AW", "label": "Aruba"},
    {"value": "AU", "label": "Australia"},
    {"value": "AT", "label": "Austria"},
    {"value": "AZ", "label": "Azerbaijan"},
    {"value": "BH", "label": "Bahrain"},
    {"value": "BD", "label": "Bangladesh"},
    {"value": "BB", "label": "Barbados"},
    {"value": "BE", "label": "Belgium"},
    {"value": "BZ", "label": "Belize"},
    {"value": "BJ", "label": "Benin"},
    {"value": "BM", "label": "Bermuda"},
    {"value": "BT", "label": "Bhutan"},
    {"value": "BO", "label": "Bolivia"},
    {"value": "AN", "label": "Bonaire, Curaçao, Saba, Sint Eustatius, Sint Maarten"},
    {"value": "BA", "label": "Bosnia and Herzegovina"},
    {"value": "BW", "label": "Botswana"},
    {"value": "BR", "label": "Brazil"},
    {"value": "VG", "label": "British Virgin Islands"},
    {"value": "BN", "label": "Brunei"},
    {"value": "BG", "label": "Bulgaria"},
    {"value": "BF", "label": "Burkina Faso"},
    {"value": "BI", "label": "Burundi"},
    {"value": "KH", "label": "Cambodia"},
    {"value": "CM", "label": "Cameroon"},
    {"value": "CA", "label": "Canada"},
    {"value": "CV", "label": "Cape Verde"},
    {"value": "KY", "label": "Cayman Islands"},
    {"value": "CF", "label": "Central African Republic"},
    {"value": "TD", "label": "Chad"},
    {"value": "CL", "label": "Chile"},
    {"value": "CN", "label": "China"},
    {"value": "CX", "label": "Christmas Island"},
    {"value": "CC", "label": "Cocos (Keeling) Islands"},
    {"value": "CO", "label": "Colombia"},
    {"value": "KM", "label": "Comoros"},
    {"value": "CG", "label": "Congo"},
    {"value": "CD", "label": "Congo (DRC)"},
    {"value": "CK", "label": "Cook Islands"},
    {"value": "CR", "label": "Costa Rica"},
    {"value": "CI", "label": "Côte d’Ivoire"},
    {"value": "HR", "label": "Croatia"},
    {"value": "CY", "label": "Cyprus"},
    {"value": "CZ", "label": "Czech Republic"},
    {"value": "DK", "label": "Denmark"},
    {"value": "DJ", "label": "Djibouti"},
    {"value": "DM", "label": "Dominica"},
    {"value": "DO", "label": "Dominican Republic"},
    {"value": "EC", "label": "Ecuador"},
    {"value": "EG", "label": "Egypt"},
    {"value": "SV", "label": "El Salvador"},
    {"value": "GQ", "label": "Equatorial Guinea"},
    {"value": "ER", "label": "Eritrea"},
    {"value": "EE", "label": "Estonia"},
    {"value": "ET", "label": "Ethiopia"},
    {"value": "FK", "label": "Falkland Islands (Islas Malvinas)"},
    {"value": "FO", "label": "Faroe Islands"},
    {"value": "FM", "label": "Federated States of Micronesia"},
    {"value": "FJ", "label": "Fiji Islands"},
    {"value": "FI", "label": "Finland"},
    {"value": "FR", "label": "France"},
    {"value": "GF", "label": "French Guiana"},
    {"value": "PF", "label": "French Polynesia"},
    {"value": "GA", "label": "Gabon"},
    {"value": "GE", "label": "Georgia"},
    {"value": "DE", "label": "Germany"},
    {"value": "GH", "label": "Ghana"},
    {"value": "GI", "label": "Gibraltar"},
    {"value": "GR", "label": "Greece"},
    {"value": "GL", "label": "Greenland"},
    {"value": "GD", "label": "Grenada"},
    {"value": "GP", "label": "Guadeloupe"},
    {"value": "GU", "label": "Guam"},
    {"value": "GT", "label": "Guatemala"},
    {"value": "GN", "label": "Guinea"},
    {"value": "GW", "label": "Guinea-Bissau"},
    {"value": "GY", "label": "Guyana"},
    {"value": "HT", "label": "Haiti"},
    {"value": "HN", "label": "Honduras"},
    {"value": "HU", "label": "Hungary"},
    {"value": "IS", "label": "Iceland"},
    {"value": "IN", "label": "India"},
    {"value": "ID", "label": "Indonesia"},
    {"value": "IQ", "label": "Iraq"},
    {"value": "IE", "label": "Ireland"},
    {"value": "IL", "label": "Israel"},
    {"value": "IT", "label": "Italy"},
    {"value": "JM", "label": "Jamaica"},
    {"value": "JP", "label": "Japan"},
    {"value": "JO", "label": "Jordan"},
    {"value": "KZ", "label": "Kazakhstan"},
    {"value": "KE", "label": "Kenya"},
    {"value": "KI", "label": "Kiribati"},
    {"value": "KW", "label": "Kuwait"},
    {"value": "KG", "label": "Kyrgyzstan"},
    {"value": "LA", "label": "Laos"},
    {"value": "LV", "label": "Latvia"},
    {"value": "LB", "label": "Lebanon"},
    {"value": "LS", "label": "Lesotho"},
    {"value": "LR", "label": "Liberia"},
    {"value": "LY", "label": "Libya"},
    {"value": "LI", "label": "Liechtenstein"},
    {"value": "LT", "label": "Lithuania"},
    {"value": "LU", "label": "Luxembourg"},
    {"value": "MO", "label": "Macao SAR"},
    {"value": "MG", "label": "Madagascar"},
    {"value": "MW", "label": "Malawi"},
    {"value": "MY", "label": "Malaysia"},
    {"value": "MV", "label": "Maldives"},
    {"value": "ML", "label": "Mali"},
    {"value": "MT", "label": "Malta"},
    {"value": "MH", "label": "Marshall Islands"},
    {"value": "MQ", "label": "Martinique"},
    {"value": "MR", "label": "Mauritania"},
    {"value": "MU", "label": "Mauritius"},
    {"value": "YT", "label": "Mayotte"},
    {"value": "MX", "label": "Mexico"},
    {"value": "MD", "label": "Moldova"},
    {"value": "MC", "label": "Monaco"},
    {"value": "MN", "label": "Mongolia"},
    {"value": "ME", "label": "Montenegro"},
    {"value": "MS", "label": "Montserrat"},
    {"value": "MA", "label": "Morocco"},
    {"value": "MZ", "label": "Mozambique"},
    {"value": "MM", "label": "Myanmar"},
    {"value": "NA", "label": "Namibia"},
    {"value": "NR", "label": "Nauru"},
    {"value": "NP", "label": "Nepal"},
    {"value": "NL", "label": "Netherlands"},
    {"value": "NC", "label": "New Caledonia"},
    {"value": "NZ", "label": "New Zealand"},
    {"value": "NI", "label": "Nicaragua"},
    {"value": "NE", "label": "Niger"},
    {"value": "NG", "label": "Nigeria"},
    {"value": "NU", "label": "Niue"},
    {"value": "NF", "label": "Norfolk Island"},
    {"value": "MK", "label": "North Macedonia"},
    {"value": "MP", "label": "Northern Mariana Islands"},
    {"value": "NO", "label": "Norway"},
    {"value": "OM", "label": "Oman"},
    {"value": "PK", "label": "Pakistan"},
    {"value": "PW", "label": "Palau"},
    {"value": "PS", "label": "Palestinian Authority"},
    {"value": "PA", "label": "Panama"},
    {"value": "PG", "label": "Papua New Guinea"},
    {"value": "PY", "label": "Paraguay"},
    {"value": "PE", "label": "Peru"},
    {"value": "PH", "label": "Philippines"},
    {"value": "PN", "label": "Pitcairn Island"},
    {"value": "PL", "label": "Poland"},
    {"value": "PT", "label": "Portugal"},
    {"value": "PR", "label": "Puerto Rico"},
    {"value": "QA", "label": "Qatar"},
    {"value": "RE", "label": "Réunion"},
    {"value": "RO", "label": "Romania"},
    {"value": "RW", "label": "Rwanda"},
    {"value": "PM", "label": "Saint Pierre and Miquelon"},
    {"value": "WS", "label": "Samoa"},
    {"value": "SM", "label": "San Marino"},
    {"value": "ST", "label": "São Tomé and Príncipe"},
    {"value": "SA", "label": "Saudi Arabia"},
    {"value": "SN", "label": "Senegal"},
    {"value": "RS", "label": "Serbia"},
    {"value": "SC", "label": "Seychelles"},
    {"value": "SL", "label": "Sierra Leone"},
    {"value": "SG", "label": "Singapore"},
    {"value": "SK", "label": "Slovakia"},
    {"value": "SI", "label": "Slovenia"},
    {"value": "SB", "label": "Solomon Islands"},
    {"value": "SO", "label": "Somalia"},
    {"value": "ZA", "label": "South Africa"},
    {"value": "KR", "label": "South Korea"},
    {"value": "ES", "label": "Spain"},
    {"value": "LK", "label": "Sri Lanka"},
    {"value": "SH", "label": "St. Helena"},
    {"value": "KN", "label": "St. Kitts and Nevis"},
    {"value": "LC", "label": "St. Lucia"},
    {"value": "VC", "label": "St. Vincent and the Grenadines"},
    {"value": "SR", "label": "Suriname"},
    {"value": "SZ", "label": "Swaziland"},
    {"value": "SE", "label": "Sweden"},
    {"value": "CH", "label": "Switzerland"},
    {"value": "TJ", "label": "Tajikistan"},
    {"value": "TZ", "label": "Tanzania"},
    {"value": "TH", "label": "Thailand"},
    {"value": "BS", "label": "The Bahamas"},
    {"value": "GM", "label": "The Gambia"},
    {"value": "TL", "label": "Timor-Leste"},
    {"value": "TG", "label": "Togo"},
    {"value": "TK", "label": "Tokelau"},
    {"value": "TO", "label": "Tonga"},
    {"value": "TT", "label": "Trinidad and Tobago"},
    {"value": "TN", "label": "Tunisia"},
    {"value": "TR", "label": "Turkey"},
    {"value": "TM", "label": "Turkmenistan"},
    {"value": "TC", "label": "Turks and Caicos Islands"},
    {"value": "TV", "label": "Tuvalu"},
    {"value": "VI", "label": "U.S. Virgin Islands"},
    {"value": "UG", "label": "Uganda"},
    {"value": "UA", "label": "Ukraine"},
    {"value": "AE", "label": "United Arab Emirates"},
    {"value": "GB", "label": "United Kingdom"},
    {"value": "US", "label": "United States"},
    {"value": "UY", "label": "Uruguay"},
    {"value": "UZ", "label": "Uzbekistan"},
    {"value": "VU", "label": "Vanuatu"},
    {"value": "VA", "label": "Vatican City"},
    {"value": "VE", "label": "Venezuela"},
    {"value": "VN", "label": "Vietnam"},
    {"value": "WF", "label": "Wallis and Futuna"},
    {"value": "YE", "label": "Yemen"},
    {"value": "ZM", "label": "Zambia"},
    {"value": "ZW", "label": "Zimbabwe"}
  ]
}
